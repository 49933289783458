<template>
  <main class="rbc-rules window" :class="{'open': open}">
    <header class="rbc-rules__header">
      <h1>{{$t("prizes-title")}}</h1>
      <button class="round" @click="goToHome">
        <img src="@/assets/icons/close.svg" />
      </button>
    </header>
    <div class="rbc-rules__content">
      <div class="rbc-availability">
        <img class="logo" src="@/assets/icons/warning.svg" />
        <span>{{$t("prizes-warning")}}</span>
      </div>

      <h1>{{$t("rbcx-tier")+" 1 - "}} {{$t("init-tier-1-points")}} {{tier1}}</h1>
        <p>{{$t("init-tier-1-sub")}}</p>
        <div class="rbx-prize">
          <div class="rbx-prize__image">
            <img src="@/assets/prizes/RBCMusic_Terrace_Pass.png" />
          </div>
          <div class="rbx-prize__content">
            <p class="rbx-prize__title">{{$t("init-tickets")}}</p>
          </div>
        </div>

     <h1>{{$t("rbcx-tier")+" 2 - "}} {{$t("init-tier-2-points")}} {{tier2}}</h1>
        <p>{{$t("init-tier-2-sub")}}</p>
        <div class="rbx-prize">
          <div class="rbx-prize__image">
            <img src="@/assets/prizes/RBCMusic_Game_Tshirt.png" />
          </div>
          <div class="rbx-prize__content">
            <p class="rbx-prize__title">{{$t("init-tshirt")}}</p>
          </div>
           <div class="rbx-prize__image">
            <img src="@/assets/prizes/RBCMusic_Game_BucketHat.png" />
          </div>
          <div class="rbx-prize__content">
            <p class="rbx-prize__title">{{$t("init-hat")}}</p>
          </div>
           <div class="rbx-prize__image">
            <img src="@/assets/prizes/RBCMusic_Game_FannyPack.png" />
          </div>
          <div class="rbx-prize__content">
            <p class="rbx-prize__title">{{$t("init-pack")}}</p>
          </div>
           <div class="rbx-prize__image">
            <img src="@/assets/prizes/RBCMusic_Game_Sunglasses.png" />
          </div>
          <div class="rbx-prize__content">
            <p class="rbx-prize__title">{{$t("init-glasses")}}</p>
          </div>
        </div>

        <h1>{{$t("rbcx-tier")+" 3 - "}} {{$t("init-tier-3-points")}} {{tier3}}</h1>
        <p>{{$t("init-tier-3-sub")}}</p>
        <div class="rbx-prize">
          <div class="rbx-prize__image">
            <img src="@/assets/prizes/RBCMusic_Game_Bandana.png" />
          </div>
          <div class="rbx-prize__content">
            <p class="rbx-prize__title">{{$t("init-essent")}}</p>
          </div>
        </div>
        
      <div class="rbc-availability">
        <img class="logo" src="@/assets/icons/warning.svg" />
        <span>{{$t("prizes-q-warn")}}</span>
      </div>
    </div>
  </main>
</template>

<script>
import firebaseService from "../services/firebase.service";

export default {
  name: "RulesView",
  props: {
    open: Boolean
  },
  data() {
    return {
      tier1: null,
      tier2: null,
      tier3: null,
      tier1Points: null,
      tier2Points: null,
      tier3Points: null
    }
  },
  beforeMount() {
    firebaseService
        .getSettings(false)
        .then((response) => {
          this.tier1 = response.tiers[0].name;
          this.tier2 = response.tiers[1].name;
          this.tier3 = response.tiers[2].name;
          this.tier1Points = response.tiers[0].min;
          this.tier2Points = response.tiers[1].min;
          this.tier3Points = response.tiers[2].min;
        });
  },
  methods: {
    goToHome() {
      this.$emit('close');
    }
  }
};
</script>
<style lang="scss" scoped>
@import '../../public/css/prizes.scss';

.rbc-rules {
  position: absolute;
  height: calc(100%);
  width: calc(100vw);

  display: flex;
  flex-direction: column;

  background-color: var(--color-background-light);
  transition: all .4s ease-in-out;

  overflow-y: auto;

  &.window {
    top: 150vh;
  }

  &.open {
    z-index: 1;
    top: 0;
  }

  .rbc-rules__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    height: 20px;
    background-color: var(--color-font-dark);
    color: var(--color-font-light);
    margin-bottom: 30px;

    h1 {
      margin: 0;
      font-size: 24px;
      font-weight: 500;
      font-family: var(--font-default);
    }

    button {
      margin-bottom: -35px;
    }
  }

  .rbc-rules__content {
    display: flex;
    flex-direction: column;
    color: var(--color-font-dark);
    height: calc(100% - 60px);
    overflow-y: auto;
    padding: 0px 20px 20px 20px;

    h1 {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    h2 {
      font-family: var(--font-extrabold);
      margin: 0 0 10px 0;
      font-size: 22px;
      font-weight: 800;
    }

    p {
      font-family: var(--font-light);
      font-size: 18px;
      font-weight: 500;
      margin: 0;
    }
  }

  .rbc-rules__footer {
    margin: auto -20px -20px -20px;
    padding: 20px;
    background-color: var(--color-font-dark);
  }
}

.rbc-rules__content .rbc-availability {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-size: 16px;
  font-weight: bold;
  color: #fb591f;
  background-color: #ffeeca;
  border-radius: 8px;
  img {
    margin: 0px 10px 0px 0px;
    width: 24px;
    height: 24px;
  }
}

.rbc-rules__content > h1 {
  font-size: 24px;
  font-weight: 800;
  color: #002750;
}

</style>