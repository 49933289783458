<template>
  <Suspense>
    <template #default>
      <App />
    </template>
    <template #fallback>
      <div class="rbc-suspenser">
        <img alt="rbc-logo" :src="getLogo()">
      </div>
    </template>
  </Suspense>
</template>

<script>
import App from './App.vue'

export default {
  name: 'SuspenserView',
  components: {
    App
  },
  methods: {
    getLogo() {
      return require(this.$i18next.language === 'fr' ? './assets/rbc-logo-notext-fr.svg' : './assets/rbc-logo-notext.svg');
    }
  }
}
</script>
<style lang="scss" scoped>
.rbc-suspender {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-background-dark);
}
</style>