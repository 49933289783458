<template>
    <div>
        <iframe
            ref="onirixIframe"
            id="onirix-frame"
            style="position:fixed;top:0;left:0;right:0;bottom:0;width:100%;height:100%;z-index:999;display:block;border:none;"
            :src="iframeUrl" allow="camera;gyroscope;accelerometer;fullscreen;geolocation">
        </iframe>
    </div>
</template>
<script>
import firebaseService from "../../services/firebase.service";
import rbcxFunctions from "../../services/functions.service";

export default {
    name: 'GameView',
    data() {
        return {
            iframeUrl: null,
            messageListener: (messageEvent) => this.onMessageReceived(messageEvent)
        }
    },
    beforeMount() {
        this.setIframeUrl();
    },
    async created() {
        window.addEventListener('message', this.messageListener);
    },
    beforeUnmount() {
        window.removeEventListener('message', this.messageListener); 
    },
    methods: {
        async setIframeUrl() {
            const settings = await firebaseService.getSettings();
            const user = await firebaseService.getUser(true);
            let scenesPlayed = '';
            if (user.playedGames && 0 < user.playedGames.length) {
                scenesPlayed = `&rbcxPlayed=${user.playedGames.map( game => game.sceneOid).join()}`;
            }
            this.iframeUrl = `${settings.onirixHost}/projects/${settings.onirixProjectOid}/webar?token=${settings.onirixProjectToken}${scenesPlayed}&locale=${this.$i18next.language.substring(0,2)}`;           
        },
        async onMessageReceived(msg) {
            if (msg && msg.data) {
                try {
                    const gameMessage = JSON.parse(msg.data);
                    if ('rbcx-game-end' === gameMessage.action) {
                        try {
                            const response = await this.saveScore(gameMessage.sceneOid, gameMessage.score);
                            this.sendMessageToStudio(response, 'score-saved');
                        } catch (error) {
                            console.error(`Error on save score`, error.message);
                            let msg = {
                                title: error.message,
                                detail: ''
                            }
                            if (error.message == 'Game already played.') {
                                msg = {
                                    type: 'played',
                                    text: this.$t('game-played'),
                                }
                            } else if (error.message == 'Unknown game') {
                                msg = {
                                    type: 'played',
                                    text: this.$t('game-location'),
                                }
                            } else {
                                const settings = await firebaseService.getSettings();
                                msg = {
                                    type: 'closed',
                                    title: this.$t('game-closed-title'),
                                    text: this.$t('game-closed-text'),
                                    detail: rbcxFunctions.getDateString(settings),
                                }
                            }
                            this.sendMessageToStudio(msg, 'error');    
                        }
                    } else if ('rbcx-go-home' === gameMessage.action) {
                        this.$router.push('/');
                    }
                } catch (error) {
                    console.debug(`Unknown message`, msg.data, error);
                }
            }
        },
        async saveScore(sceneOid, score) {
            const response = await firebaseService.saveScore(sceneOid, score);
            return response;
        },
        sendMessageToStudio(msg, action) {
            msg['origin']= 'RBCxMusic';
            msg['action']= action;
            this.$refs.onirixIframe.contentWindow.postMessage(JSON.stringify(msg), '*');
        }
    }
}
</script>
<style scoped lang="scss">

</style>