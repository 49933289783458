<template>
  <main class="rbc-init">
    <div class="rbc-init__content">
      <div class="rbc-init__img">
        <img alt="Findxs rules" :src="getLogo()" />
      </div>
      <h2>{{$t("init-title")}}</h2>
      <ol>
        <li>{{$t("init-li1")}}</li>
        <li>{{$t("init-li2")}}</li>
        <li>{{$t("init-li3")}}</li>
      </ol>
      <h2>{{$t("init-prizes")}}</h2>
      <div class="rbc-prizes">

        <h1>{{$t("rbcx-tier")+" 1 - "}} {{$t("init-tier-1-points")}} {{tier1}}</h1>
        <p>{{$t("init-tier-1-sub")}}</p>
        <div class="rbx-prize">
          <div class="rbx-prize__image">
            <img src="@/assets/prizes/RBCMusic_Terrace_Pass.png" />
          </div>
          <div class="rbx-prize__content">
            <p class="rbx-prize__title">{{$t("init-tickets")}}</p>
          </div>
        </div>
                
        <h1>{{$t("rbcx-tier")+" 2 - "}} {{$t("init-tier-2-points")}} {{tier2}}</h1>
        <p>{{$t("init-tier-2-sub")}}</p>
        <div class="rbx-prize">
          <div class="rbx-prize__image">
            <img src="@/assets/prizes/RBCMusic_Game_Tshirt.png" />
          </div>
          <div class="rbx-prize__content">
            <p class="rbx-prize__title">{{$t("init-tshirt")}}</p>
          </div>
           <div class="rbx-prize__image">
            <img src="@/assets/prizes/RBCMusic_Game_BucketHat.png" />
          </div>
          <div class="rbx-prize__content">
            <p class="rbx-prize__title">{{$t("init-hat")}}</p>
          </div>
           <div class="rbx-prize__image">
            <img src="@/assets/prizes/RBCMusic_Game_FannyPack.png" />
          </div>
          <div class="rbx-prize__content">
            <p class="rbx-prize__title">{{$t("init-pack")}}</p>
          </div>
           <div class="rbx-prize__image">
            <img src="@/assets/prizes/RBCMusic_Game_Sunglasses.png" />
          </div>
          <div class="rbx-prize__content">
            <p class="rbx-prize__title">{{$t("init-glasses")}}</p>
          </div>
        </div>
        
        <h1>{{$t("rbcx-tier")+" 3 - "}} {{$t("init-tier-3-points")}} {{tier3}}</h1>
        <p>{{$t("init-tier-3-sub")}}</p>
        <div class="rbx-prize">
          <div class="rbx-prize__image">
            <img src="@/assets/prizes/RBCMusic_Game_Bandana.png" />
          </div>
          <div class="rbx-prize__content">
            <p class="rbx-prize__title">{{$t("init-essent")}}</p>
          </div>
        </div>
        
        <h1>{{$t("init-redemp")}}</h1>
        <p>{{$t("init-luck")}}</p>
      </div>
      <p>
        <b>{{$t("init-legal-t")}}</b><br/>{{$t("init-p")}}<a :href="legalLink" target="_blank">{{$t("rbcx-policy-url")}}</a>.
      </p>
    </div>
    <footer class="rbc-init__footer">
      <button @click="goToRegister">{{$t("rbcx-get-started")}}</button>
    </footer>
  </main>
  <RulesView :window="true" :open="rules" @close="rules = false"></RulesView>
</template>

<script>
import firebaseService from "../services/firebase.service";
import RulesView from './RulesView.vue';

export default {
  name: "InitView",
  components: {
    RulesView
  },
  props: {
    window: Boolean,
    open: Boolean,
  },
  data() {
    return {
      tier1: null,
      tier2: null,
      tier3: null,
      rules: false
    }
  },
  beforeMount() {
    firebaseService
        .getSettings(false)
        .then((response) => {
          this.tier1 = response.tiers[0].name;
          this.tier2 = response.tiers[1].name;
          this.tier3 = response.tiers[2].name;
        });
  },
  methods: {
    goToRegister() {
      this.$router.push("/login");
    },
    openRules() {
      this.rules = true;
    },
    getLogo() {
      return require(this.$i18next.language === 'fr' ? '../assets/rules-fr.jpg' : '../assets/rules.jpg');
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../public/css/prizes.scss";
.rbc-init {
  height: 100%;
  .rbc-init__content {
    height: calc(100% - 140px);
    overflow-y: auto;
    padding: 20px 20px 30px 20px;
    .rbc-init__img {
      border-radius: 8px;
      img {
        width: 100%;
        border-radius: 8px;
      }
    }
    h2 {
      margin: 20px 0px 16px 0px;
      font-size: 24px;
      font-weight: 800;
      color: #002750;
    }
    ol {
      font-size: 18px;
      font-weight: 500;
      color: #002750;
      margin-top: 16px;
      padding-bottom: 30px;
      padding-left: 34px;
      border-bottom: 1px solid #c5c9cd;
      margin-bottom: 30px;
      li:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
  .rbc-init__footer {
    height: 50px;
    padding: 20px;
    background-color: #002750;
  }
}

.rbc-prizes {
  h1 {
    font-size: 22px;
    font-weight: bold;
    color: #002750;
  }
  .rbx-prize .rbx-prize__content .rbx-prize__title {
    margin-top: 0px;
  }
}

</style>