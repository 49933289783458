<template>
  <main class="rbc-home">
    <header class="rbc-home__header">
      <img alt="Findxs logo" :src="getLogo()" />
      <button class="round" @click="openRules">
        <img class="logo" src="@/assets/icons/book.svg" />
      </button>
      <button class="round" @click="openPrizes">
        <img class="logo" src="@/assets/icons/prizes.svg" />
      </button>
      <LanguageSelectorView></LanguageSelectorView>
    </header>
    <div v-if="!isLoading && user && active" class="rbc-home__content">
      <img alt="Findxs logo" :src="getFindxLogo()" />
      <div class="rbc-info">{{$t("home-info1")}}</div>
      <div class="rbc-info">{{$t("home-info2")}}</div>
      <ul class="rbc-tierlist">
        <li class="rbc-tierlist__tier" v-for="(tier, index) in settings.tiers" :key="index"
          :class="{'rbc-tierlist__tier--active': isActiveTier(tier) }">
          <div class="rbc-tierlist__tier-header">
            <span>{{$t("rbcx-tier")}} {{tier.name}}</span>
            <span>{{tier.min}} {{tier.max ? '- ' + tier.max : ''}} pts</span>
          </div>
          <div v-if="isActiveTier(tier)">
            <UserScoreView :user="user"></UserScoreView>
            <button class="success" @click="openRedeem">
              {{$t("home-redeem")}}
            </button>
          </div>
        </li>
        <li v-if="isUnderTiers()">
          <UserScoreView :user="user"></UserScoreView>
        </li>
      </ul>
    </div>
    <div v-if="!active" class="rbc-home__content rbc-home__content--inactive">
      <img src="@/assets/icons/calendar-error.svg" />
      <h1>{{$t("home-inactive")}}</h1>
      <p>{{$t("home-inactive-p")}}</p>
      <p class="date" v-html="$t('home-inactive-date')"></p>
    </div>
    <footer class="rbc-home__footer">
      <button v-if="active" @click="startGame" :class="{'loading': !hasGeo}">
        <span class="icon"></span>
        {{ user && user.playedGames && user.playedGames.length > 0 ? $t("home-keep-play") : $t("home-play") }}
      </button>
    </footer>
  </main>
  <ErrorDialogView v-if="error" :error="error" @close="$router.go()"></ErrorDialogView>
  <RulesView :window="true" :open="rules" @close="rules = false"></RulesView>
  <PrizesView :open="prizes" @close="prizes = false"></PrizesView>
</template>

<script>
import Constants from "../constants";
import firebaseService from "../services/firebase.service";
import rbcxFunctions from "../services/functions.service";
import UserScoreView from './shared/UserScoreView.vue';
import ErrorDialogView from './shared/ErrorDialogView.vue';
import RulesView from './RulesView.vue';
import PrizesView from './PrizesView.vue';
import LanguageSelectorView from './shared/LanguageSelectorView.vue';

export default {
  name: "HomeView",
  components: {
    UserScoreView,
    ErrorDialogView,
    RulesView,
    PrizesView,
    LanguageSelectorView
  },
  data() {
      return {
        env: Constants.ENV,
        settings: null,
        user: null,
        isLoading: true,
        active: true,
        dateString: '',
        error: null,
        rules: false,
        prizes: false,
        hasGeo: false
      }
  },
  async created() {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log(position);
        this.hasGeo = true;
      },
      (error) => {
        console.log(error);
        this.error = {
          title: "error-geo",
          message: "error-geo-msg",
          closable: false
        }
      },
      {
        enableHighAccuracy: true,
        timeout: 10000
      }
    );
  },
  beforeMount() {
    this.getSettings();
    this.getUser();
  },
  methods: {
    getSettings() {
      firebaseService
        .getSettings()
        .then((response) => {
          this.settings = response;
          this.checkActive();
          this.getDateString();
          this.isLoading = false;
      })
      .catch((error) => {
        console.error(error);
        this.settings = null;
        this.error = {
          title: "error-game",
          message: "error-game-msg",
          closable: false
        }
      });
    },
    async getUser() {
      try {
        this.user = await firebaseService.getUser(true);
      } catch (error) {
        console.error(error);
        this.error = {
          title: "error-user",
          message: "error-user-msg",
          closable: false
        }
      }
    },
    isActiveTier(tier) {
      return tier.min <= this.user.score && (tier.max ? this.user.score <= tier.max : true);
    },
    isUnderTiers() {
      return !this.user.score || this.user.score < this.settings.tiers[this.settings.tiers.length - 1].min;
    },
    checkActive() {
      const currentDate = new Date();
      const start = new Date(this.settings.periodStart);
      const end = new Date(this.settings.periodEnd);

      this.active = this.settings.status === 'rbcx-ongoing' && 
        (start <= currentDate.getTime() && currentDate.getTime() <= end);
    },
    openRules() {
      this.rules = true;
    },
    openPrizes() {
      this.prizes = true;
    },
    openRedeem() {
      this.$router.push('/redeem');
    },
    startGame() {
      this.$router.push('/play');
    },
    getDateString() {
      if (!this.active) {
        this.dateString = rbcxFunctions.getDateString(this.settings);
      }
    },
    getLogo() {
      return require(this.$i18next.language === 'fr' ? '../assets/rbc-logo-notext-fr.svg' : '../assets/rbc-logo-notext.svg');
    },
    getFindxLogo() {
      return require(this.$i18next.language === 'fr' ? '../assets/findxs-logo-fr.svg' : '../assets/findxs-logo.svg');
    }
  }
};
</script>
<style lang="scss" scoped>
.rbc-home {
  height: calc(100% - 40px);
  width: calc(100vw - 40px);
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: var(--color-font-dark);
  background-image: url('../assets/soundwaves.svg');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;

  .rbc-info {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: var(--color-font-light);
    font-family: var(--font-default);
    margin: -6px 0px 20px 0px;
  }

  .rbc-home__header {
    display: flex;
    align-items: baseline;
    
    > img {
      width: 40%;
      max-width: 111px;
      margin-right: auto;
    }

    button {
      margin-right: 10px;
    }
  }

  .rbc-home__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 118px;
      margin-bottom: 20px;
    }

    h1 {
      font-family: var(--font-light);
      margin: 0 0 20px 0;
      font-size: 20px;
      font-weight: 500;
      text-align: center;
      color: var(--color-font-light);
    }

    &.rbc-home__content--inactive {
      width: calc(100% - 40px);
      color: var(--color-font-dark);
      background-color: var(--color-background-light);
      padding: 20px;
      border-radius: 10px;
      margin-bottom: 43px;
      text-align: center;

      img {
        margin-top: -70px;
        margin-bottom: 14px;
      }

      h1 {
        margin: 0;
        font-size: 32px;
        font-weight: 800;
        color: var(--color-font-dark);
        font-family: var(--font-extrabold);
      }

      p {
        font-family: var(--font-light);
        margin: 10px 0 0 0;
        font-size: 18px;
        font-weight: 500;
      }

      .date {
        margin-top: 20px;
        font-family: var(--font-extrabold);
        font-weight: 800;
      }
    }
  }

  .rbc-home__footer {
    margin: -20px;
    padding: 20px;
    min-height: 50px;
  }
}

.rbc-tierlist {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  li {
    width: 100%;
  }

  .rbc-tierlist__tier {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    .rbc-tierlist__tier-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      width: calc(100% - 40px);
      padding: 14px 20px;
      border-radius: 6px;
      background-color: #11355b;
      color: var(--color-font-light);

      span {
        font-size: 17px;
        font-weight: bold;
      }
    }

    &.rbc-tierlist__tier--active {
      align-items: initial;

      .rbc-tierlist__tier-header {
        background-color: #ffeeca;
        border-radius: 6px 6px 0 0;
        color: var(--color-font-dark);
      }

      div:last-child {
        background-color: var(--color-background-light);
        border-radius: 0 0 6px 6px;
        display: flex;
        flex-direction: column;

        button {
          margin: 0 20px 20px 20px;
          width: auto;
        }
      }
    }
  }
}
</style>