<template>
  <main class="rbc-rules" :class="{'open': open, 'window': window}">
    <header v-if="window" class="rbc-rules__header">
      <h1>{{$t("rules-title")}}</h1>
      <button class="round" @click="goToHome">
        <img class="logo" src="@/assets/icons/close.svg" />
      </button>
    </header>
    <div class="rbc-rules__content">
      <img alt="Findxs rules" :src="getLogo()" />
      <h2>{{$t("rules-welcome")}}</h2>
        <ol>
          <li>{{$t("rules-li1")}}</li>
          <li>{{$t("rules-li2")}}</li>
          <li>{{$t("rules-li3")}}</li>
        </ol>

        <p class="rbc-policy">
          <a :href="legalLink" target="_blank">{{$t("rbcx-policy")}}</a>.
        </p>

        <p>{{$t("rules-p")}}<a :href="legalLink" target="_blank">{{$t("rbcx-policy")}}</a>.</p>
    </div>
    <footer v-if="!window" class="rbc-rules__footer">
      <button @click="goToRegister">
        {{$t("rbcx-get-started")}}
      </button>
    </footer>
  </main>
</template>

<script>
import Constants from "../constants";

export default {
  name: "RulesView",
  props: {
    window: Boolean,
    open: Boolean
  },
  data() {
        return {
          legalLink: Constants.LEGAL_LINK,
        }
  },
  methods: {
    goToHome() {
      this.$emit('close');
    },
    goToRegister() {
      this.$router.push('/register');
    },
    getLogo() {
      return require(this.$i18next.language === 'fr' ? '../assets/rules-fr.jpg' : '../assets/rules.jpg');
    }
  }
};
</script>
<style lang="scss" scoped>

.rbc-rules__content > h2 {
  margin: 20px 0px 16px 0px;
  font-size: 24px;
  font-weight: 800;
  color: #002750;
}
.rbc-rules__content > ol {
  font-size: 18px;
  font-weight: 500;
  color: #002750;
  margin-top: 16px;
  padding-bottom: 30px;
  padding-left: 34px;
  border-bottom: 1px solid #c5c9cd;
  margin-bottom: 30px;
  li:not(:last-child) {
    margin-bottom: 20px;
  }
}

.rbc-rules__content > p.rbc-policy {
  text-align: center;
  font-size: 20px !important;
}

.rbc-rules {
  position: absolute;
  height: calc(100%);
  width: calc(100vw);

  display: flex;
  flex-direction: column;

  background-color: var(--color-background-light);
  transition: all .4s ease-in-out;

  overflow-y: auto;

  &.window {
    top: 150vh;
  }

  &.open {
    z-index: 1;
    top: 0;
  }

  .rbc-rules__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    height: 20px;
    background-color: var(--color-font-dark);
    color: var(--color-font-light);
    margin-bottom: 30px;

    h1 {
      margin: 0;
      font-size: 24px;
      font-weight: 500;
      font-family: var(--font-default);
    }

    button {
      margin-bottom: -35px;
    }
  }

  .rbc-rules__content {
    display: flex;
    flex-direction: column;
    color: var(--color-font-dark);
    height: calc(100% - 60px);
    overflow-y: auto;
    padding: 0px 20px 20px 20px;

    img {
      margin-bottom: 20px;
      border-radius: 10px;
      width: 100%;
    }

    h2 {
      font-family: var(--font-extrabold);
      margin: 0 0 10px 0;
      font-size: 22px;
      font-weight: 800;
    }

    p {
      font-family: var(--font-default);
      font-size: 18px;
      font-weight: 500;
      margin: 0;
    }
    p + p {
      margin-top: 1em;
    }
  }

  .rbc-rules__footer {
    margin: auto -20px -20px -20px;
    padding: 20px;
    background-color: var(--color-font-dark);
  }
}
</style>